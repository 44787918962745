import { useState } from "react";
import { FirestoreCollection, getFirestoreDB } from "../api/firebase";
import { addDoc, collection } from "@firebase/firestore";
import { Author } from "../types/Author";

const collectionId: FirestoreCollection = "author";

function useAuthor() {
  const [data, setData] = useState<Author[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);

  const firestore = getFirestoreDB();
  const collectionRef = collection(firestore, collectionId);

  const addAuthors = async (authors: Author[]) => {
    setProcessing(true);
    try {
      for (const item of authors) {
        await addDoc(collection(firestore, collectionId), item);
        console.log(`Aurhor added with ID: ${item.name}`);
      }
    } catch (error) {
      throw error;
    } finally {
      setProcessing(false);
    }
  };

  return {
    data,
    processing,
    addAuthors,
  };
}

export default useAuthor;
