export const getDisplayDate = (timestamp: string) => {
  const date = new Date(timestamp);
  const now = new Date();

  // Check if the date is today
  if (date.toDateString() === now.toDateString()) {
    return `Today, ${date.toLocaleString(undefined, {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;
  }

  // Check if the date was yesterday
  const yesterday = new Date();
  yesterday.setDate(now.getDate() - 1);
  if (date.toDateString() === yesterday.toDateString()) {
    return `Yesterday, ${date.toLocaleString(undefined, {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    })}`;
  }

  // For other dates, format as 10th July, 2024
  const day = date.getDate();
  const month = date.toLocaleString(undefined, { month: "long" });
  const year = date.getFullYear();
  return `${day} ${month}, ${year}`;
};
