import { useState } from "react";
import Container from "../../components/Container";
import Title from "../../components/Title";
import Image from "../../components/Image";
import Input from "../../components/Input";
import Button from "../../components/Button";
import TextArea from "../../components/TextArea";
import { useSocialMedia } from "../../../hooks/useSocialMedia";
import { sendEmail } from "../../../api/services/sendEmail";
import Lottie from "lottie-react";
import Glidding from "../../../assets/lottiee/contact-us.json";

const InstaQRColor = require("../../../assets/logo/insta_qr_color.png");
const FBQRColor = require("../../../assets/logo/fb_qr_color.jpg");

function ContactUs() {
  const [from, setFrom] = useState("");
  const [message, setMessage] = useState("");

  const { gotoFacebook, gotoInstagram } = useSocialMedia();

  const sendMessage = async () => {
    if (!from || !message) {
      alert(
        "Feel free to send us your message and email. We're eager to hear from you! Rest assured, we will keep it secret. 😇"
      );
      return;
    }
    const payload = {
      to_name: "Sochne Aankha Support",
      from_name: from,
      message,
    };
    sendEmail(payload).then(() => {
      setFrom("");
      setMessage("");
    });
  };

  return (
    <Container className="flex flex-col">
      <Lottie
        className="h-auto w-4/12 lg:w-3/12 object-cover self-center"
        animationData={Glidding}
        loop={true}
      />

      <Container className="flex flex-col items-center lg:items-start lg:flex-row lg:justify-around">
        <Container className="flex flex-col my-8 w-10/12 lg:w-6/12">
          <Title variant="m" className="text-center mb-8">
            “We'd Love to Hear from You”
          </Title>
          <Input
            type="email"
            id="email"
            placeholder="example@example.com"
            className="mb-8"
            value={from}
            onChange={(e) => setFrom(e.target.value)}
            required
          />
          <TextArea
            id="message"
            placeholder="Your message"
            className="mb-8"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={8}
            required
          />
          <Button
            label="Send"
            onPress={sendMessage}
            className="self-center w-4/12"
          />
        </Container>
        <Container className="flex flex-col my-8 w-auto">
          <Title variant="m" className="text-center mb-8">
            “Let's Connect”
          </Title>
          <Container className="flex justify-center">
            <Image
              src={InstaQRColor}
              className="h-60 w-60 ms-4 md:ms-8"
              onClick={gotoInstagram}
            />
            <Image
              src={FBQRColor}
              className="h-60 w-60 ms-4 md:ms-8"
              onClick={gotoFacebook}
            />
          </Container>
        </Container>
      </Container>
    </Container>
  );
}

export default ContactUs;
