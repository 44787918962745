import { useEffect, useState } from "react";

import useLiterature from "../hooks/useLiterature";
import useModalPopup from "../hooks/useModalPopup";
import { Literature } from "../types/Literature";
import AdaptiveContainer from "./components/AdaptiveContainer";
import Container from "./components/Container";
import Image from "./components/Image";
import ListItem from "./components/ListItem";
import Text from "./components/Text";
import Title from "./components/Title";

import DetailPopup from "./elements/modal/DetailPopup";

const IMAGE = "https://picsum.photos/800/600";

function BlogPage() {
  const [selectedItem, setSelectedItem] = useState<Literature | null>(null);

  const { Modal, closeModal, openModal } = useModalPopup();

  const { getBlogs, data: blogs } = useLiterature();

  useEffect(() => {
    getBlogs(50);
  }, []);

  const openLiteratureDetail = (item: Literature) => {
    setSelectedItem(item);
    setTimeout(() => {
      openModal();
    }, 50);
  };

  const renderItem = (item: Literature) => {
    return (
      <ListItem key={item.id} item={item} onPress={openLiteratureDetail} />
    );
  };

  return (
    <AdaptiveContainer className="bg-white dark:bg-zinc-800">
      <Image src={IMAGE} className="h-96 w-full" />
      <Title variant="xl" className="text-center py-8">
        “Thoughts and Reflections”
      </Title>
      <Text className="px-8">
        Welcome to Thoughts and Reflections, where ideas come to life. Our blog
        is a space for sharing insights, experiences, and perspectives on a wide
        range of topics. Whether you're looking for inspiration, thoughtful
        analysis, or a fresh viewpoint, you'll find it here. Explore our diverse
        collection of posts, use the filter to find the content that speaks to
        you, and let the featured image at the top set the tone for your reading
        experience. Join us on this journey of discovery and conversation.
      </Text>

      <AdaptiveContainer className="p-8 mt-16">
        <Container className="mx-auto max-w-7xl">
          {blogs.map(renderItem)}
        </Container>
      </AdaptiveContainer>
      {selectedItem && (
        <DetailPopup
          Modal={Modal}
          closeModal={closeModal}
          detail={selectedItem}
        />
      )}
    </AdaptiveContainer>
  );
}

export default BlogPage;
