import { MutableRefObject } from "react";
export type Menu = {
  name: string;
  id: string;
  ref: MutableRefObject<HTMLDivElement | null>;
};

type MenuItemProps = {
  menu: Menu;
  isSelected: boolean;
  onNavigate: (
    id: string,
    ref: MutableRefObject<HTMLDivElement | null>
  ) => void;
};

function MenuItem({ menu, isSelected, onNavigate }: MenuItemProps) {
  const { id, name, ref } = menu;
  const onClick = () => {
    onNavigate(id, ref);
  };
  return (
    <li>
      <a
        id={id}
        href={`#${id}`}
        className={`block py-2 px-3 ${
          isSelected
            ? "text-white bg-blue-700 md:bg-transparent md:text-blue-700 md:dark:text-blue-500"
            : "text-gray-900 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:dark:hover:text-blue-500 dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent"
        } outline-none md:p-0 `}
        aria-current="page"
        onClick={onClick}
      >
        {name}
      </a>
    </li>
  );
}

export default MenuItem;
