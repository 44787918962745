export const useSocialMedia = () => {
  const gotoInstagram = () =>
    window.open(process.env.REACT_APP_INSTA_LINK, "_blank");
  const gotoFacebook = () =>
    window.open(process.env.REACT_APP_FACEBOOK_LINK, "_blank");

  return {
    gotoFacebook,
    gotoInstagram,
  };
};
