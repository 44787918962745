import { useState } from "react";
import { FirestoreCollection, getFirestoreDB } from "../api/firebase";
import { getDocs, addDoc, collection, query, where } from "@firebase/firestore";
import { Subscriber } from "../types/Subscriber";

const collectionId: FirestoreCollection = "subscriber";

function useSubscriber() {
  const [data, setData] = useState<Subscriber[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);

  const firestore = getFirestoreDB();
  const collectionRef = collection(firestore, collectionId);

  const checkAlreadySubscribed = async (email: string): Promise<boolean> => {
    const q = query(collectionRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const addSubscriber = async (email: Subscriber) => {
    try {
      await addDoc(collection(firestore, collectionId), email);
    } catch (error) {
      console.log("error", JSON.stringify(error));
      throw error;
    }
  };

  return {
    data,
    processing,
    addSubscriber,
    checkAlreadySubscribed,
  };
}

export default useSubscriber;
