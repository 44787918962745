import React from "react";

type AdaptiveContainerProps = React.HTMLAttributes<HTMLDivElement>;
function AdaptiveContainer({ className, ...props }: AdaptiveContainerProps) {
  return (
    <div className={`bg-gray-100 dark:bg-zinc-600 ${className}`} {...props} />
  );
}

export default AdaptiveContainer;
