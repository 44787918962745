import Author from "./Author";
import Image from "./Image";
import Title from "./Title";
import Text from "./Text";
import AdaptiveContainer from "./AdaptiveContainer";
import Container from "./Container";
import { Author as AuthorType } from "../../types/Author";

type CardProps = {
  onPress: VoidFunction;
  title: string;
  description: string;
  image: string | undefined;
  author: AuthorType;
  createdAt: string;
};
function Card({
  onPress,
  title,
  description,
  image,
  author,
  createdAt,
}: CardProps) {
  return (
    <AdaptiveContainer
      onClick={onPress}
      className="bg-gray-100 rounded-2xl shadow-xl hover:shadow-2xl transition-shadow duration-300 min-w-72 max-w-lg dark:bg-zinc-700"
    >
      <Image
        alt="title"
        src={image}
        className="rounded-t-2xl h-64 w-full object-cover lg:h-80 border-2 dark:border-0"
      />
      <Container className="p-4">
        <Title variant="m" className="mb-3">
          {title}
        </Title>

        <Text className="line-clamp-6 text-start">{description}</Text>

        <Container className="mt-3 lg:mt-6">
          <Author
            id={author.id}
            name={author.name}
            avatar={author.dp}
            createdAt={createdAt}
          />
        </Container>
      </Container>
    </AdaptiveContainer>
  );
}

export default Card;
