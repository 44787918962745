import React, { HtmlHTMLAttributes } from "react";
type ButtonProps = {
  onPress: VoidFunction;
  label: string;
} & HtmlHTMLAttributes<HTMLButtonElement>;

function Button({ onPress, label, className, ...props }: ButtonProps) {
  return (
    <button
      onClick={onPress}
      className={`bg-gray-500 hover:bg-gray-700 active:bg-gray-950 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ${className} dark:bg-zinc-700 dark:hover:bg-zinc-800`}
    >
      {label}
    </button>
  );
}

export default Button;
