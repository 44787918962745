import { Firestore, getFirestore } from "@firebase/firestore";
import { FirebaseStorage, getStorage } from "@firebase/storage";
import { initializeApp } from "@firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAERN8j2WMyeQkym8iTkJDuiE3kYhl-knI",
  authDomain: "sochne-aankha.firebaseapp.com",
  databaseURL:
    "https://sochne-aankha-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "sochne-aankha",
  storageBucket: "sochne-aankha.appspot.com",
  messagingSenderId: "928790620817",
  appId: "1:928790620817:web:85b7b4c585010613dc6d30",
  measurementId: "G-5XH5Z0JGHN",
};

const firebaseApp = initializeApp(firebaseConfig);

let firestore: Firestore;
let firebaseStorage: FirebaseStorage;

export function getFirestoreDB() {
  if (!firestore) {
    firestore = getFirestore(firebaseApp);
  }

  return firestore;
}

export function getFirebaseStorage() {
  if (!firebaseStorage) {
    firebaseStorage = getStorage(firebaseApp);
  }

  return firebaseStorage;
}

export type FirestoreCollection = "literature" | "subscriber" | "author";
