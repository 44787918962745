import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getGallery } from "../../../api/data/gallery";
import useModalPopup from "../../../hooks/useModalPopup";
import { Gallery as GalleryType } from "../../../types/Gallery";
import Button from "../../components/Button";
import Container from "../../components/Container";
import Title from "../../components/Title";
import GalleryPopup from "../modal/GalleryPopup";
import Renderer from "./Renderer";

function Gallery() {
  const [media, setMedia] = useState<GalleryType[]>([]);

  const Modal = useModalPopup(true);
  const navigate = useNavigate();

  useEffect(() => {
    getGallery().then((response) => {
      setMedia(
        response?.data.filter((item) => item.media_type === "IMAGE") || []
      );
    });
  }, []);

  const navigateToGallery = () => {
    navigate("/gallery");
  };

  const posts = media.slice(0.9);

  return (
    <Container className="flex">
      <Container className="text-center">
        <Title variant="l">Gallery of Dreams</Title>
        <Renderer media={posts} onPress={Modal.openModal} />
        <Container className="flex justify-center mt-8">
          <Button
            label="Explore"
            onPress={navigateToGallery}
            className="w-52 mt-4 "
          />
        </Container>
      </Container>

      {media.length > 0 && (
        <GalleryPopup
          Modal={Modal.Modal}
          closeModal={Modal.closeModal}
          posts={media}
        />
      )}
    </Container>
  );
}

export default Gallery;
