import emailjs from "emailjs-com";

type Payload = {
  to_name: string;
  from_name: string;
  message: string;
};

export const sendEmail = async (payload: Payload) => {
  const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
  const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
  const userId = process.env.REACT_APP_EMAILJS_USER_ID;

  try {
    if (!serviceId || !templateId || !userId)
      throw new Error("Credential mismatch");

    const response = await emailjs.send(serviceId, templateId, payload, userId);

    alert("Thank you for your words 😇. We will get back to you soon.");
    console.log("Email sent:", response.status, response.text);
  } catch (error) {
    alert(
      "Currently unable to send you message. Please use our social handles or try again later."
    );
    console.error("Error sending email:", error);
  }
};
