import { useCallback, useMemo, useState, ReactNode } from "react";
import ModalBase from "../pages/elements/modal/ModalBase";

export type ModalProps = {
  children: ReactNode;
};

function useModalPopup(transparentBg?: boolean) {
  const [showModal, setShowModal] = useState(false);
  const closeModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const openModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const Modal = ({ children }: ModalProps) => {
    const memoizedModal = useMemo(
      () => (
        <ModalBase
          visibility={showModal}
          children={children}
          closeModal={closeModal}
          transparentBg={!!transparentBg}
        />
      ),
      [children]
    );

    return <>{showModal ? memoizedModal : null}</>;
  };

  return { closeModal, openModal, Modal };
}

export default useModalPopup;
