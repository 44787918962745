import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useLiterature from "../../../hooks/useLiterature";
import useModalPopup from "../../../hooks/useModalPopup";
import { Literature as LiteratureType } from "../../../types/Literature";
import Button from "../../components/Button";
import Card from "../../components/Card";
import Container from "../../components/Container";
import Title from "../../components/Title";
import DetailPopup from "../modal/DetailPopup";
import parse from "html-react-parser";

function Blog() {
  const [selectedData, setSelectedData] = useState<LiteratureType | null>(null);
  const Modal = useModalPopup(true);
  const navigate = useNavigate();

  const { getBlogs, data: blogs } = useLiterature();

  useEffect(() => {
    getBlogs();
  }, []);

  const openDetailPopup = (data: LiteratureType) => {
    setSelectedData(data);
    setTimeout(() => {
      Modal.openModal();
    }, 100);
  };

  const navigateToBlog = () => {
    navigate("/blog");
  };

  const renderCard = (card: LiteratureType, index: number) => {
    const onCardPress = () => {
      openDetailPopup(card);
    };
    const { id, title, description, image, author, createdAt } = card;
    return (
      <Card
        key={id}
        onPress={onCardPress}
        title={title}
        description={description}
        image={image}
        author={author}
        createdAt={createdAt}
      />
    );
  };

  return (
    <Container>
      <Title variant="l" className="text-center">
        Recommended Blog Posts
      </Title>
      <Container className="mt-8 grid grid-cols-1 gap-2 sm:grid-cols-2 lg:grid-flow-col-dense">
        {blogs.map(renderCard)}
      </Container>
      <Container className="flex justify-center mt-8">
        <Button
          label="Explore"
          onPress={navigateToBlog}
          className="w-52 mt-4 "
        />
      </Container>
      {selectedData && (
        <DetailPopup
          Modal={Modal.Modal}
          closeModal={Modal.closeModal}
          detail={selectedData}
        />
      )}
    </Container>
  );
}

export default Blog;
