import React from "react";

type TitleProps = {
  variant: "xl" | "l" | "m" | "s";
} & React.HTMLAttributes<HTMLHeadingElement>;

function Title({ className, children, variant, ...props }: TitleProps) {
  const className_ =
    variant === "xl"
      ? "text-4xl font-extrabold leading-none tracking-tight text-gray-580 md:text-5xl dark:text-gray-200"
      : variant === "l"
      ? "text-3xl font-semibold leading-none tracking-tight text-gray-580 dark:text-gray-200"
      : variant === "m"
      ? "text-xl font-semibold leading-none tracking-tight text-gray-580 md:text-xl dark:text-gray-200"
      : "text-sm font-semibold text-gray-580 dark:text-gray-200 lg:text-lg";

  return (
    <h1
      className={`hover:cursor-default ${className} ${className_}`}
      {...props}
    >
      {children}
    </h1>
  );
}

export default Title;
