import Lottie from "lottie-react";
import AdaptiveContainer from "./components/AdaptiveContainer";
import Container from "./components/Container";
import Image from "./components/Image";
import Text from "./components/Text";
import Title from "./components/Title";
import Facebook from "../assets/lottiee/facebook.json";
import Instagram from "../assets/lottiee/instagram.json";
import { useSocialMedia } from "../hooks/useSocialMedia";
import MediaGallery from "./elements/gallery/MediaGallery";

const IMAGE = "https://picsum.photos/800/600";

function GalleryPage() {
  const { gotoFacebook, gotoInstagram } = useSocialMedia();

  return (
    <AdaptiveContainer className="bg-white dark:bg-zinc-800">
      <Image src={IMAGE} className="h-96 w-full" />
      <Title variant="xl" className="text-center py-8">
        “Visual Chronicles”
      </Title>
      <Text className="px-8">
        Welcome to Visual Chronicles, where every photo and video captures a
        vibrant story through my lens. Explore captivating landscapes, dynamic
        cityscapes, and moments of everyday beauty. We regularly update our
        adventures, including places we visit, hikes, and treks. Stay tuned for
        real-time updates and exciting adventures by following us on Facebook
        and Instagram!
      </Text>

      <Container className="flex flex-row justify-center mt-8">
        <Lottie
          className="h-24 w-24 mx-8"
          animationData={Instagram}
          loop={true}
          onClick={gotoInstagram}
        />
        <Lottie
          className="h-24 w-24 mx-8"
          animationData={Facebook}
          loop={true}
          onClick={gotoFacebook}
        />
      </Container>

      <AdaptiveContainer className="p-8 mt-16">
        <MediaGallery />
      </AdaptiveContainer>
    </AdaptiveContainer>
  );
}

export default GalleryPage;
