import React, { useState } from "react";
import Container from "../../components/Container";
import FilterItem, { LiteratureFilter as Filter } from "./FilterItem";

const Filters = ["All", "Poem", "Story"] as Filter[];

type LiteratureFilterProps = {
  onSelected: (filter: Filter) => void;
};
function LiteratureFilter({ onSelected }: LiteratureFilterProps) {
  const [selected, setSelected] = useState(Filters[0]);
  const renderFilters = (filter: Filter) => {
    const onSelected_ = () => {
      setSelected(filter);
      onSelected(filter);
    };

    return (
      <FilterItem
        key={filter}
        filter={filter}
        selected={selected === filter}
        onSelected={onSelected_}
      />
    );
  };

  return (
    <Container className="flex flex-row justify-center">
      {Filters.map(renderFilters)}
    </Container>
  );
}

export default LiteratureFilter;
