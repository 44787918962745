import { useEffect } from "react";
import useModalPopup from "../../../hooks/useModalPopup";
import Button from "../../components/Button";
import Text from "../../components/Text";
import Title from "../../components/Title";
import DetailPopup from "../modal/DetailPopup";
import Card from "../../components/Card";
import { useNavigate } from "react-router-dom";
import Container from "../../components/Container";
import useLiterature from "../../../hooks/useLiterature";

function RandomCards() {
  const { openModal, closeModal, Modal } = useModalPopup();
  const navigate = useNavigate();
  const { getRandomRead, data: literatures } = useLiterature();
  const data = literatures[0];

  useEffect(() => {
    getRandomRead();
  }, []);

  const gotoLiteraturePage = () => {
    navigate("literature");
  };
  const showCardDetails = () => {
    openModal();
  };

  return (
    <>
      <Container className="p-8 rounded-lg flex flex-col items-center md:justify-around md:flex md:flex-row-reverse">
        <Container className="max-w-4xl flex flex-col items-center">
          <Title variant="l" className="mb-6 text-center">
            Random Reads
          </Title>
          <Text className="pl-2 text-pretty">
            Welcome to "Random Reads", where you never know what you'll find! On
            the provided card, explore a mix of randomly selected blogs, poems,
            and stories. Enjoy the surprise and let each piece inspire you in
            unexpected ways.
          </Text>
          <Button
            label="Explore"
            onPress={gotoLiteraturePage}
            className="w-52 mt-8 lg:mt-16 "
          />
        </Container>
        {data && (
          <Container className="mt-8">
            <Card
              onPress={showCardDetails}
              title={data.title}
              description={data.description}
              image={data.image}
              author={data.author}
              createdAt={data.createdAt}
            />
          </Container>
        )}
      </Container>
      {data && (
        <DetailPopup Modal={Modal} closeModal={closeModal} detail={data} />
      )}
    </>
  );
}

export default RandomCards;
