import React from "react";
import Text from "../../components/Text";

export type LiteratureFilter = "All" | "Poem" | "Story";
export type GalleryFilter = "Image" | "Video";
type FilterItemProps = {
  filter: LiteratureFilter | GalleryFilter;
  selected: boolean;
  onSelected: VoidFunction;
};

function FilterItem({ filter, onSelected, selected }: FilterItemProps) {
  return (
    <Text
      className={`${
        selected
          ? "bg-slate-200 dark:bg-slate-400 text-gray-500"
          : "bg-transparent"
      } py-4 px-8 rounded-lg`}
      onClick={onSelected}
    >
      {filter}
    </Text>
  );
}

export default FilterItem;
