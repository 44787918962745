import CrossIcon from "../../../assets/icons/CrossIcon";
import { ModalProps } from "../../../hooks/useModalPopup";
import { Literature } from "../../../types/Literature";
import AdaptiveContainer from "../../components/AdaptiveContainer";
import Author from "../../components/Author";
import Button from "../../components/Button";
import Container from "../../components/Container";
import IconButton from "../../components/IconButton";
import Image from "../../components/Image";
import Text from "../../components/Text";
import Title from "../../components/Title";

type DetailPopupProps = {
  Modal: (props: ModalProps) => JSX.Element;
  closeModal: VoidFunction;
  detail: Literature;
};

function DetailPopup({ detail, Modal, closeModal }: DetailPopupProps) {
  const { title, description, image, author, createdAt } = detail;
  return (
    <Modal>
      <Container className="max-h-screen">
        {/*content*/}
        <AdaptiveContainer className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
          {/*header*/}
          <Container className="flex justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
            <Title variant="m">{title}</Title>
            <IconButton Icon={CrossIcon} onPress={closeModal} />
          </Container>

          {/*body*/}
          <Container>
            <Image
              alt="title"
              src={image}
              className="h-80 w-full object-cover lg:h-96"
            />
            <Container className="relative p-6 flex-auto">
              <Text className="my-4 text-justify">{description}</Text>
              <Container className="mt-4 lg:mt-14">
                <Author
                  id={author.id}
                  name={author.name}
                  avatar={author.dp}
                  createdAt={createdAt}
                />
              </Container>
            </Container>
          </Container>

          {/*footer*/}
          <Container className="flex items-center justify-end p-5 border-t border-solid border-blueGray-200 rounded-b">
            <Button label="Close" onPress={closeModal} className="uppercase " />
          </Container>
        </AdaptiveContainer>
      </Container>
    </Modal>
  );
}

export default DetailPopup;
