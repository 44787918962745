import { ReactNode } from "react";
import Container from "../../components/Container";

type PopupModalProps = {
  visibility: boolean;
  closeModal: VoidFunction;
  children: ReactNode;
  transparentBg: boolean;
};
const ModalBase = ({
  visibility,
  children,
  transparentBg,
  closeModal,
}: PopupModalProps) => {
  return visibility ? (
    <>
      <Container className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <Container className="relative w-auto my-6 mx-auto max-w-4xl">
          <Container
            className={`border-0 rounded-lg shadow-lg relative flex flex-col w-full ${
              transparentBg ? "bg-transparent" : "bg-white"
            } outline-none focus:outline-none`}
          >
            {children}
          </Container>
        </Container>
      </Container>
      <Container className="opacity-80 fixed inset-0 z-40 bg-black" />
    </>
  ) : null;
};

export default ModalBase;
