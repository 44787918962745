import { useState } from "react";
import ArrowLeft from "../../../assets/icons/ArrowLeft";
import ArrowRight from "../../../assets/icons/ArrowRight";
import CrossIcon from "../../../assets/icons/CrossIcon";
import { ModalProps } from "../../../hooks/useModalPopup";
import { Gallery } from "../../../types/Gallery";
import Container from "../../components/Container";
import IconButton from "../../components/IconButton";
import Image from "../../components/Image";

type GalleryPopupProps = {
  Modal: (props: ModalProps) => JSX.Element;
  closeModal: VoidFunction;
  posts: Gallery[];
};

function GalleryPopup({ posts, Modal, closeModal }: GalleryPopupProps) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const urls = posts.map((item, index) => {
    return { url: item.media_url, index };
  });

  if (posts.length === 0) return null;
  const selectedImage = urls[selectedIndex].url;

  const seePreviousImage = () => {
    setSelectedIndex((current) => {
      return current === 0 ? posts.length - 1 : current - 1;
    });
  };

  const seeNextImage = () => {
    setSelectedIndex((current) => {
      return current === posts.length - 1 ? 0 : current + 1;
    });
  };

  return (
    <Modal>
      <Container className="h-lvh flex justify-center items-center py-8">
        <Container className="absolute top-12 right-4 bg-white h-16 w-16 flex justify-center items-center opacity-50 hover:opacity-90 rounded-full">
          <IconButton Icon={CrossIcon} onPress={closeModal} />
        </Container>
        <Container
          onClick={seePreviousImage}
          className="absolute left-0 top-2/4 bg-white h-16 w-16 flex justify-center items-center opacity-30 hover:opacity-90"
        >
          <ArrowLeft stroke="black" />
        </Container>
        <Image
          alt="title"
          src={selectedImage}
          className="h-full w-full object-cover"
        />
        <Container
          onClick={seeNextImage}
          className="absolute right-0 top-2/4 bg-white h-16 w-16 flex justify-center items-center opacity-30 hover:opacity-90"
        >
          <ArrowRight stroke="black" />
        </Container>
      </Container>
    </Modal>
  );
}

export default GalleryPopup;
