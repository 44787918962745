import { getDisplayDate } from "../../utilities/Date";
import Container from "./Container";
import Image from "./Image";
import Text from "./Text";
import Title from "./Title";
type AuthorProps = {
  id: string;
  name: string;
  createdAt: string;
  avatar?: string;
};
function Author({ name, avatar, createdAt }: AuthorProps) {
  const date = getDisplayDate(createdAt);
  return (
    <Container className="flex flex-row items-center">
      <Image
        alt="author"
        src={avatar}
        className="h-10 w-10 rounded-full object-cover lg:h-12 lg:w-12"
      />
      <Container className="ml-2">
        <Title variant="s">{name}</Title>
        {!!createdAt && <Text className="text-sm lg:text-lg">{date}</Text>}
      </Container>
    </Container>
  );
}

export default Author;
