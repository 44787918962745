import { useState } from "react";
import Container from "../../components/Container";
import Title from "../../components/Title";
import Text from "../../components/Text";
import Input from "../../components/Input";
import Button from "../../components/Button";
import Lottie from "lottie-react";
import Newsletter from "../../../assets/lottiee/newsletter.json";
import { v4 as uuidv4 } from "uuid";
import useSubscriber from "../../../hooks/useSubscriber";

function NewsLetter() {
  const [email, setEmail] = useState<string>("");
  const { addSubscriber, checkAlreadySubscribed } = useSubscriber();

  const validateEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };

  const subscribe = async () => {
    if (!email || !validateEmail(email)) {
      alert(
        "Please enter your valid email – we promise to keep it safe and spam-free!😇"
      );
      return;
    }
    try {
      const emailExists = await checkAlreadySubscribed(email);
      if (emailExists) {
        setEmail("");
        alert("It looks like you're already subscribed. Thank you!😇");
        return;
      }
      await addSubscriber({
        id: uuidv4(),
        email: email.toLowerCase(),
      });
      setEmail("");
      alert(
        "Thank you for subscribing 😇! Get ready to explore captivating travel stories, literary gems, and stunning photo content.🥳 "
      );
    } catch (error) {
      console.log("error", JSON.stringify(error));
      alert(
        "Something went wrong on our end. Please try again later or contact support if the issue persists.😟"
      );
    }
  };
  return (
    <Container className="flex flex-col items-center md:flex-row">
      <Container className="flex w-8/12 lg:w-4/12">
        <Lottie
          className="w-full h-full"
          animationData={Newsletter}
          loop={true}
        />
      </Container>
      <Container className="flex flex-col px-8 md:w-3/6">
        <Title variant="m" className="text-center">
          Stay Informed and Inspired!
        </Title>
        <Text className="text-start mt-4">
          Join our community of innovators and stay ahead with the latest
          updates, insights, and trends in the tech world. Each month, we
          deliver exclusive content, expert tips, and exciting news straight to
          your inbox. Subscribe now to fuel your passion for technology and
          never miss a beat!
        </Text>
        <Input
          className="mt-8"
          placeholder="example@example.com"
          value={email}
          type="email"
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          label="Subscribe"
          onPress={subscribe}
          className="mt-4 self-end"
        />
      </Container>
    </Container>
  );
}

export default NewsLetter;
