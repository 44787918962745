import { useState } from "react";
import { FirestoreCollection, getFirestoreDB } from "../api/firebase";
import {
  getDocs,
  addDoc,
  collection,
  query,
  where,
  limit as limitQuery,
} from "@firebase/firestore";
import { Literature } from "../types/Literature";
// import {
//   getLiteratures as getTestLiteratures,
//   getRandomCard,
// } from "../api/data/literature";
// import { getBlogs as getTestBlogs } from "../api/data/blog";

const collectionId: FirestoreCollection = "literature";

function useLiterature() {
  const [data, setData] = useState<Literature[]>([]);
  const [processing, setProcessing] = useState<boolean>(false);

  const firestore = getFirestoreDB();
  const collectionRef = collection(firestore, collectionId);

  const getLiteratures = async (limit = 7) => {
    // setData(getTestLiteratures(limit) as Literature[]);
    // return;
    setProcessing(true);
    const q = query(
      collectionRef,
      where("type", "!=", "BLOG"),
      limitQuery(limit)
    );

    try {
      const docs = await getDocs(q);
      const literatures: Literature[] = [];
      docs.forEach((doc) => {
        literatures.push({ ...doc.data() } as Literature);
      });
      setData(literatures);
    } catch (error) {
      throw error;
    } finally {
      setProcessing(false);
    }
  };

  const getBlogs = async (limit = 7) => {
    // setData(getTestBlogs() as Literature[]);
    // return;
    setProcessing(true);
    const q = query(
      collectionRef,
      where("type", "==", "BLOG"),
      limitQuery(limit)
    );

    try {
      const docs = await getDocs(q);
      const blogs: Literature[] = [];
      docs.forEach((doc) => {
        blogs.push({ ...doc.data() } as Literature);
      });
      setData(blogs);
    } catch (error) {
      throw error;
    } finally {
      setProcessing(false);
    }
  };

  const getPoems = async (limit = 7) => {
    // setData(getTestLiteratures(limit) as Literature[]);
    // return;
    setProcessing(true);
    const q = query(collectionRef, where("type", "==", "POEM"));

    try {
      const docs = await getDocs(q);
      const poems: Literature[] = [];
      docs.forEach((doc) => {
        poems.push({ ...doc.data() } as Literature);
      });
      setData(poems);
    } catch (error) {
      throw error;
    } finally {
      setProcessing(false);
    }
  };

  const getStories = async (limit = 7) => {
    // setData(getTestLiteratures(limit) as Literature[]);
    // return;
    setProcessing(true);
    const q = query(
      collectionRef,
      where("type", "==", "STORY"),
      limitQuery(limit)
    );

    try {
      const docs = await getDocs(q);
      const stories: Literature[] = [];
      docs.forEach((doc) => {
        stories.push({ ...doc.data() } as Literature);
      });
      setData(stories);
    } catch (error) {
      throw error;
    } finally {
      setProcessing(false);
    }
  };

  const getRandomRead = async () => {
    // setData([getRandomCard()] as Literature[]);
    // return;
    setProcessing(true);
    const q = query(collectionRef);

    try {
      const docs = await getDocs(q);
      const literatures: Literature[] = [];
      docs.forEach((doc) => {
        literatures.push({ ...doc.data() } as Literature);
      });
      const randomIndex = Math.floor(Math.random() * docs.size);
      setData([literatures[randomIndex] as Literature]);
    } catch (error) {
      throw error;
    } finally {
      setProcessing(false);
    }
  };

  const uploadLiteratureData = async (literatureData: Literature[]) => {
    try {
      for (const item of literatureData) {
        await addDoc(collection(firestore, collectionId), item);
        console.log(`Document added with ID: ${item.id}`);
      }
    } catch (error) {
      throw error;
    }
  };

  return {
    data,
    processing,
    getLiteratures,
    getBlogs,
    getPoems,
    getStories,
    getRandomRead,
    uploadLiteratureData,
  };
}

export default useLiterature;
