import { MutableRefObject, useState } from "react";
import MenuItem, { Menu } from "./MenuItem";
// const logo = require("../../../assets/logo/logo.jpg");
const colorLogo = require("../../../assets/logo/logo_color.png");
const logo = require("../../../assets/logo/logo_white.png");

type NavMenuProps = {
  menus: Menu[];
};

function NavMenu({ menus }: NavMenuProps) {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [selectedMenu, setSelectedMenu] = useState<string>("home");

  const toggleShow = () => {
    setShowMenu((old) => !old);
  };
  const onNavigate = (
    id: string,
    ref: MutableRefObject<HTMLDivElement | null>
  ) => {
    if (ref.current === null) return;
    setSelectedMenu(id);
    const top = ref.current.offsetTop - 64;
    window.scrollTo({ top, behavior: "smooth" });
    setShowMenu(false);
  };

  const isDarkMode =
    window.matchMedia &&
    window.matchMedia("(prefers-color-scheme: dark)").matches;

  return (
    <nav className="bg-white dark:bg-gray-900 fixed w-full z-20 top-0 start-0 border-b border-gray-200 dark:border-gray-600">
      <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
        <a
          href="https://sochneaankha.com/"
          className="flex items-center space-x-3 rtl:space-x-reverse"
        >
          <img
            src={isDarkMode ? logo : colorLogo}
            className="w-40 object-contain"
            alt="Sochne Aankha Logo"
          />
        </a>
        <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
          <button
            data-collapse-toggle="navbar-sticky"
            type="button"
            className="inline-flex items-center p-2 w-10 h-10 border-0 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="navbar-sticky"
            aria-expanded="false"
            onClick={toggleShow}
          >
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 17 14"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M1 1h15M1 7h15M1 13h15"
              />
            </svg>
          </button>
        </div>
        <div
          className={`items-center justify-between ${
            showMenu ? "" : "hidden"
          }  w-full md:flex md:w-auto md:order-1`}
          id="navbar-sticky"
        >
          <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
            {menus.map((item) => {
              return (
                <MenuItem
                  key={item.id}
                  menu={item}
                  onNavigate={onNavigate}
                  isSelected={selectedMenu === item.id}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default NavMenu;
