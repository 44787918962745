import React from "react";
import { Gallery } from "../../../types/Gallery";
import Container from "../../components/Container";
import Image from "../../components/Image";
import Video from "../../components/Video";

type RendererProps = {
  onPress: VoidFunction;
  media: Gallery[];
};

function Renderer({ onPress, media }: RendererProps) {
  const renderGalleryItem = (post: Gallery) => {
    if (post.media_type === "IMAGE") {
      return (
        <Image
          key={post.id}
          onClick={onPress}
          src={post.media_url}
          className="rounded-lg"
        />
      );
    } else if (post.media_type === "VIDEO") {
      return (
        <Video key={post.id} className="my-video-class" controls>
          <source src={post.media_url} type="video/mp4" />
          Your browser does not support the video tag.
        </Video>
      );
    } else return null;
  };

  return (
    <Container className="mt-8 grid grid-cols-3 justify-items-center sm:grid-cols-4 lg:grid-cols-5 gap-2">
      {media.map(renderGalleryItem)}
    </Container>
  );
}

export default Renderer;
