import { BrowserRouter } from "react-router-dom";
import RootRoute from "./route/Root";

function App() {
  return (
    <BrowserRouter>
      <RootRoute />
    </BrowserRouter>
  );
}

export default App;
