import axios, { AxiosResponse } from "axios";
import { Gallery } from "../../types/Gallery";

type Result = {
  data: Gallery[];
  paging: {
    cursors: {
      after: string;
      before: string;
    };
    next: string;
  };
};

export const getGallery = async (limit?: number) => {
  const limit_ = limit || 20;
  const url = `https://graph.instagram.com/me/media?fields=id,caption,children,media_url,timestamp,media_type,permalink&limit=${limit_}&access_token=${process.env.REACT_APP_INSTA_KEY}`;

  try {
    const instaPosts = await axios.get<any, AxiosResponse<Result, any>>(url);

    let mediaItems: Gallery[] = [];

    for (const item of instaPosts.data.data) {
      if (item.media_type === "IMAGE") {
        mediaItems.push(item);
      } else if (item.media_type === "CAROUSEL_ALBUM" && item.children) {
        const childMediaPromises = item.children.data.map(async (child) => {
          const childDetails = await getMediaDetailById(child.id);
          return childDetails.media_type === "IMAGE" ? childDetails : null;
        });

        const childImages = await Promise.all(childMediaPromises);
        mediaItems = mediaItems.concat(childImages.filter(Boolean));
      }
    }
    return { data: mediaItems };
  } catch (error) {
    console.log("Error fetching insta posts", error);
  }
};

export const getMediaDetailById = async (id: number) => {
  try {
    const childUrl = `https://graph.instagram.com/${id}?fields=id,media_type,media_url&access_token=${process.env.REACT_APP_INSTA_KEY}`;
    const response = await fetch(childUrl);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log("Error fetching media detail by id", error);
  }
};
