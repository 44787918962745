import React from "react";
import { Literature } from "../../types/Literature";
import AdaptiveContainer from "./AdaptiveContainer";
import Container from "./Container";
import Text from "./Text";
import Title from "./Title";

type ListItemProps = {
  item: Literature;
  onPress: (item: Literature) => void;
};
function ListItem({ item, onPress }: ListItemProps) {
  const { title, description, createdAt, author } = item;

  const onPress_ = () => {
    onPress(item);
  };

  return (
    <AdaptiveContainer
      onClick={onPress_}
      className="bg-slate-300 p-4 my-4 lg:my-8 rounded-2xl shadow-xl hover:shadow-2xl transition-shadow duration-300 dark:bg-zinc-700"
    >
      <Title variant="m">{title}</Title>
      <Text className="text-base line-clamp-2 text-start pt-4">
        {description}
      </Text>
      <Container className=" flex flex-row pt-4 justify-end">
        <Container>
          <Text className="text-sm text-end">{createdAt}</Text>
          <Text className="text-sm text-end">{author.name}</Text>
        </Container>
      </Container>
    </AdaptiveContainer>
  );
}

export default ListItem;
