import React, { InputHTMLAttributes } from "react";

type InputProps = InputHTMLAttributes<HTMLInputElement>;

function Input({ className, ...props }: InputProps) {
  return (
    <input
      className={`shadow appearance-none border rounded w-full py-4 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline ${className}`}
      {...props}
    />
  );
}

export default Input;
