import React from "react";
import parse from "html-react-parser";

function Text({
  className,
  children,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={`text-center text-lg font-normal text-gray-500 dark:text-gray-100 hover:cursor-default ${className}`}
      {...props}
    >
      {parse(`${children}`)}
    </div>
  );
}

export default Text;
