import React, { useEffect, useRef, useState } from "react";
import { getGallery } from "../../../api/data/gallery";
import useModalPopup from "../../../hooks/useModalPopup";
import { Gallery } from "../../../types/Gallery";
import Container from "../../components/Container";
import { GalleryFilter as Filter } from "../filter/FilterItem";
import GalleryFilter from "../filter/GalleryFilter";
import GalleryPopup from "../modal/GalleryPopup";
import Renderer from "./Renderer";

function MediaGallery() {
  const [media, setMedia] = useState<Gallery[]>([]);
  const { Modal, openModal, closeModal } = useModalPopup(true);

  const allMediaRef = useRef<Gallery[]>([]);

  useEffect(() => {
    getGallery(50).then((response) => {
      allMediaRef.current = response?.data || [];
      setMedia(
        response?.data.filter((item) => item.media_type === "IMAGE") || []
      );
    });
  }, []);

  const filterItems = (selected: Filter) => {
    setMedia(
      allMediaRef.current.filter(
        (item) => item.media_type.toLowerCase() === selected.toLowerCase()
      )
    );
  };
  return (
    <Container className="mt-8">
      <GalleryFilter onSelected={filterItems} />
      <Renderer media={media} onPress={openModal} />
      {media.length > 0 && (
        <GalleryPopup Modal={Modal} closeModal={closeModal} posts={media} />
      )}
    </Container>
  );
}

export default MediaGallery;
