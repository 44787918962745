import AdaptiveContainer from "./components/AdaptiveContainer";
import Container from "./components/Container";
import Image from "./components/Image";
import Title from "./components/Title";
import Text from "./components/Text";
import { Literature } from "../types/Literature";
import ListItem from "./components/ListItem";
import LiteratureFilter from "./elements/filter/LiteratureFilter";
import { LiteratureFilter as Filter } from "./elements/filter/FilterItem";
import { useCallback, useEffect, useState } from "react";
import DetailPopup from "./elements/modal/DetailPopup";
import useModalPopup from "../hooks/useModalPopup";
import useLiterature from "../hooks/useLiterature";

const IMAGE = "https://picsum.photos/800/600";

function LiteraturePage() {
  const [literatureItems, setliteratureItems] = useState<Literature[]>([]);
  const [selectedItem, setSelectedItem] = useState<Literature | null>(null);

  const { Modal, closeModal, openModal } = useModalPopup();
  const { getLiteratures, data: literatures } = useLiterature();

  useEffect(() => {
    setliteratureItems(literatures);
  }, [literatures]);

  useEffect(() => {
    getLiteratures(50);
  }, []);

  const openLiteratureDetail = (item: Literature) => {
    setSelectedItem(item);
    setTimeout(() => {
      openModal();
    }, 50);
  };
  const filterItems = useCallback(
    (selected: Filter) => {
      if (selected === "All") {
        setliteratureItems(literatures);
      } else {
        setliteratureItems(
          literatures.filter(
            (item) => item.type.toLowerCase() === selected.toLowerCase()
          )
        );
      }
    },
    [literatures]
  );

  const renderItem = (item: Literature) => {
    return (
      <ListItem key={item.id} item={item} onPress={openLiteratureDetail} />
    );
  };

  return (
    <AdaptiveContainer className="bg-white dark:bg-zinc-800">
      <Image src={IMAGE} className="h-96 w-full" />
      <Title variant="xl" className="text-center py-8">
        “Literary Treasures”
      </Title>
      <Text className="px-8">
        Welcome to Literary Treasures, a haven for lovers of the written word.
        Dive into our curated collection of short stories, poems, and creative
        expressions that capture the essence of human experience. Use our
        intuitive filter to discover works that resonate with your soul, and let
        the inspiring image at the top of the page set the mood for your
        literary journey. Whether you seek to escape, reflect, or dream, there's
        a piece here waiting to be discovered by you.
      </Text>

      <AdaptiveContainer className="p-8 mt-16">
        <Container className="mb-16">
          <LiteratureFilter onSelected={filterItems} />
        </Container>
        <Container className="mx-auto max-w-7xl">
          {literatureItems.map(renderItem)}
        </Container>
      </AdaptiveContainer>
      {selectedItem && (
        <DetailPopup
          Modal={Modal}
          closeModal={closeModal}
          detail={selectedItem}
        />
      )}
    </AdaptiveContainer>
  );
}

export default LiteraturePage;
