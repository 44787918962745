import MediaHandles from "./MediaHandles";
import "./Home.css";
import Title from "../../components/Title";
import Text from "../../components/Text";
import Container from "../../components/Container";

const IntroImage = require("../../../assets/logo/logo.jpg");

function Home() {
  return (
    <Container className="flex flex-row justify-center items-center lg:max-w-screen-2xl mx-auto">
      <Container className="mx-2 flex flex-col justify-center md:flex-row-reverse items-center">
        <img
          alt="intro"
          src={IntroImage}
          className="m-8 w-96 h-96 object-cover border-4 border-gray-400 filter grayscale intro-img lg:h-96 lg:w-96"
        />
        <Container>
          <Title variant="xl" className="text-center">
            Sochne Aankha
          </Title>
          <Title variant="m" className="mt-4 mb-8 text-center">
            See. Explore. Share.
          </Title>
          <Text className="pl-2 text-justify">
            Welcome to a digital space where every photo, blog, and poem invites
            us to embark on a journey of discovery, uncovering the profound
            layers of meaning within our shared human experiences. Here, we're
            encouraged to approach the world with a mindful eye, observing the
            intricate details that often go unnoticed, and to embrace each
            moment with an open heart, ready to receive the wisdom and beauty it
            has to offer. Let's traverse this vast landscape together, exploring
            the richness of life's tapestry and finding inspiration in the
            stories it weaves.
          </Text>
        </Container>
      </Container>
      <MediaHandles />
    </Container>
  );
}

export default Home;
