import { useNavigate } from "react-router-dom";
import { authors } from "../../../api/data/author";
import { getBlogs } from "../../../api/data/blog";
import useAuthor from "../../../hooks/useAuthor";
import useLiterature from "../../../hooks/useLiterature";
import Container from "../../components/Container";
import Image from "../../components/Image";
import Text from "../../components/Text";
import Title from "../../components/Title";

const logo = require("../../../assets/logo/logo.jpg");

function Footer() {
  const navigate = useNavigate();

  const navigateToLiterature = () => navigate("literature");
  const navigateToGallery = () => navigate("gallery");
  const navigateToBlog = () => navigate("blog");
  const { uploadLiteratureData } = useLiterature();
  const { addAuthors } = useAuthor();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const populateLiteratures = () => {
  //   // console.log("blogs", getBlogs());
  //   uploadLiteratureData(getBlogs());
  // };

  // const populateAuthors = () => {
  //   addAuthors(authors);
  // };

  return (
    <Container className="flex flex-col">
      <Container className="flex justify-between">
        <Container className="flex items-center" onClick={scrollToTop}>
          <Image
            src={logo}
            className="w-20 h-20 object-cover rounded-full md:w-28 md:h-28"
            alt="Sochne Aankha Logo"
          />
          <Container className="mx-2">
            <Title variant="xl" className="text-slate-500 font-serif">
              Sochne
            </Title>
            <Title variant="xl" className="text-slate-500 font-serif">
              Aankha
            </Title>
          </Container>
        </Container>
        <Container className="flex w-6/12 lg:w-4/12 justify-around lg:justify-between">
          <Container>
            <Title
              variant="s"
              className="text-slate-500 md:text-xl"
              onClick={navigateToLiterature}
            >
              Literature
            </Title>
            <Text
              className="text-slate-500 text-sm md:text-lg"
              onClick={navigateToLiterature}
            >
              Poem
            </Text>
            <Text
              className="text-slate-500 text-sm md:text-lg"
              onClick={navigateToLiterature}
            >
              Story
            </Text>
          </Container>
          <Container>
            <Title
              variant="s"
              className="text-slate-500 md:text-xl"
              onClick={navigateToGallery}
            >
              Gallery
            </Title>
            <Text
              className="text-slate-500 text-sm md:text-lg"
              onClick={navigateToGallery}
            >
              Image
            </Text>
            <Text
              className="text-slate-500 text-sm md:text-lg"
              onClick={navigateToGallery}
            >
              Video
            </Text>
          </Container>
          <Container>
            <Title
              variant="s"
              className="text-slate-500 md:text-xl"
              onClick={navigateToBlog}
            >
              Blog
            </Title>
          </Container>
        </Container>
        <Container></Container>
      </Container>
      <Text className="text-slate-500 mt-8 text-sm">
        © 2024 Sochne Aankha. All right resorved.
      </Text>
      {/* <Text onClick={populateAuthors}>Add authors</Text>
      <Text onClick={populateLiteratures}>Add literatures</Text> */}
    </Container>
  );
}

export default Footer;
