import { VideoHTMLAttributes, useState } from "react";
import AvatarIcon from "../../assets/icons/AvatarIcon";
import Container from "./Container";

function Video({
  children,
  ...props
}: React.DetailedHTMLProps<
  VideoHTMLAttributes<HTMLVideoElement>,
  HTMLVideoElement
>) {
  const [error, setError] = useState(false);

  const handleVideoError = () => {
    setError(true);
  };

  return error ? (
    <Container className={props.className}>
      <AvatarIcon />
    </Container>
  ) : (
    <video {...props} onError={handleVideoError}>
      {children}
    </video>
  );
}

export default Video;
