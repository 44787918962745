import { ImgHTMLAttributes, useState } from "react";
import AvatarIcon from "../../assets/icons/AvatarIcon";
import Container from "./Container";
function Image({
  alt,
  ...props
}: React.DetailedHTMLProps<
  ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>) {
  const [error, setError] = useState(false);

  const handleImageError = () => {
    setError(true);
  };
  return error ? (
    <Container className={props.className}>
      <AvatarIcon />
    </Container>
  ) : (
    <img alt={alt} {...props} onError={handleImageError} />
  );
}

export default Image;
