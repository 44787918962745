import { HtmlHTMLAttributes } from "react";
import Container from "./Container";

type IconButtonProps = {
  Icon: () => JSX.Element;
  onPress: VoidFunction;
} & HtmlHTMLAttributes<HTMLDivElement>;

function IconButton({ Icon, onPress, className, ...props }: IconButtonProps) {
  return (
    <Container
      className={`justify-center items-center ${className}`}
      onClick={onPress}
      {...props}
    >
      <Icon />
    </Container>
  );
}

export default IconButton;
