import { useRef } from "react";
import AdaptiveContainer from "./components/AdaptiveContainer";
import Container from "./components/Container";
import Blog from "./elements/blog/Blog";
import ContactUs from "./elements/contact/ContactUs";
import Footer from "./elements/footer/Footer";
import Gallery from "./elements/gallery/Gallery";
import Home from "./elements/home/Home";
import Literature from "./elements/literature/Literature";
import NavMenu from "./elements/nav/NavMenu";
import NewsLetter from "./elements/newsletter/NewsLetter";
import RandomCards from "./elements/random/Random";

function LandingPage() {
  const homeRef = useRef<HTMLDivElement | null>(null);
  const literatureRef = useRef<HTMLDivElement | null>(null);
  const galleryRef = useRef<HTMLDivElement | null>(null);
  const blogsRef = useRef<HTMLDivElement | null>(null);
  const contactRef = useRef<HTMLDivElement | null>(null);
  const newsletterRef = useRef<HTMLDivElement | null>(null);
  const footerRef = useRef<HTMLDivElement | null>(null);

  const MENUS = [
    { name: "Home", id: "home", ref: homeRef },
    { name: "Literature", id: "literature", ref: literatureRef },
    { name: "Gallery", id: "gallery", ref: galleryRef },
    { name: "Blogs", id: "blogs", ref: blogsRef },
    { name: "Contact", id: "contact", ref: contactRef },
  ];

  return (
    <AdaptiveContainer>
      <NavMenu menus={MENUS} />
      <AdaptiveContainer className="pt-24 bg-white dark:bg-zinc-800">
        <section id="home" className="" ref={homeRef}>
          <Home />
          <AdaptiveContainer className="mt-8">
            <RandomCards />
          </AdaptiveContainer>
        </section>

        <section
          id="gallery"
          className="bg-gray-100 mt-16 rounded-md"
          ref={galleryRef}
        >
          <AdaptiveContainer className="p-8">
            <Gallery />
          </AdaptiveContainer>
        </section>

        <section
          id="literature"
          className="bg-gray-100 mt-16 rounded-md"
          ref={literatureRef}
        >
          <AdaptiveContainer className="p-8">
            <Literature />
          </AdaptiveContainer>
        </section>

        <section
          id="blog"
          className="bg-gray-100 mt-16 rounded-md"
          ref={blogsRef}
        >
          <AdaptiveContainer className="p-8">
            <Blog />
          </AdaptiveContainer>
        </section>
        <section
          id="contact"
          className="bg-gray-100 mt-16 rounded-md"
          ref={contactRef}
        >
          <AdaptiveContainer className="p-8">
            <ContactUs />
          </AdaptiveContainer>
        </section>
        <section
          id="newsLetter"
          className="bg-gray-100 mt-16 rounded-md"
          ref={newsletterRef}
        >
          <AdaptiveContainer className="">
            <NewsLetter />
          </AdaptiveContainer>
        </section>
        <section id="footer" className="mt-16 rounded-md" ref={footerRef}>
          <AdaptiveContainer className="p-8">
            <Footer />
          </AdaptiveContainer>
        </section>
      </AdaptiveContainer>
    </AdaptiveContainer>
  );
}

export default LandingPage;
